<template lang="pug">
form.form-horizontal.application-new(
  @submit.prevent = 'onSubmit'
  novalidate
)
  .card.mb-0.border-bottom-0
    .card-header {{ $t('title') }} - {{ workflowPolicy.productType.human }}
    .card-body
      .row: .col-lg-6
        fi-form-field(
          :label = '$t("product")'
          :state = '!$v.applicationObj.applicationFields.productId.$error'
        )
          template(#label)
            i.fa.fa-spinner.fa-pulse.fa-pull-right(v-if = '$vueLoading.isLoading("products:fetch")')
          template(#error)
            .error-message(v-if = '!$v.applicationObj.applicationFields.productId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = '$v.applicationObj.applicationFields.productId.$model'
            :options       = 'allowedProductOptions'
            :disabled      = '$vueLoading.isLoading("products:fetch")'
            :placeholder   = '$t("productPlaceholder")'
            :class         = '{ "is-invalid": $v.applicationObj.applicationFields.productId.$error }'
            required
            sm
          )
        fi-form-field.animated.fadeIn(
          v-if   = 'applicationProduct.creditIssuerId'
          :label = '$t("creditIssuer")'
        ) {{ creditIssuer.text }}

  .card.mb-0.border-bottom-0
    .card-header {{ $t('customerTitle') }}
    application-customer-form-fields(
      v-if           = 'workflowPolicy.privateCustomer'
      :customer-data = '$v.applicationObj.privateApplication'
      :product       = 'applicationProduct'
      :segments      = 'allowedSegments'
      :sectors       = 'allowedSectors'
      :id-doc-expire = 'idDocExpireDate'
      @input         = 'selectCustomer'
    )
    application-corporate-customer-form(
      v-else
      :customer-data = 'newCorporateApplication()'
      :product       = 'applicationProduct'
      :segments      = 'allowedSegments'
      :sectors       = 'allowedSectors'
      @input         = 'selectCustomer'
    )

  .card.mb-0.border-bottom-0
    .card-header {{ $t('dataTitle') }}
    .card-body
      .row
        .col-lg-6
          application-data-form-fields(
            :application-data = '$v.applicationObj.dataFields'
            :product          = 'applicationProduct'
            :fee-configs      = 'feeConfigs'
          )

    application-seller-list.mb-0.border-bottom-0(
      v-if     = 'workflowPolicy.isLeasing || containsHirePurchaseInfo'
      :sellers = '$v.applicationObj.applicationFields.sellers.$model'
      @update  = 'updateSeller'
    )
      template(#alert)
        .alert.alert-danger(v-if = '!$v.applicationObj.applicationFields.sellers.required') {{ $t('sellerRequired') }}

  .card.mb-0.border-bottom-0.animated.fadeIn(v-if = 'containsHirePurchaseInfo')
    .card-header {{ $t('purchaseTitle') }}
    .card-body
      application-purchase-info-form-fields(
        :application-data = '$v.applicationObj.purchaseFields'
        editing
      )

  application-custom-fields-data.mb-0.border-bottom-0(
    v-if = '!isLoading'
    :key = 'applicationCustomFields.length'
    :custom-fields = 'applicationObj.customFieldValues || []'
    :options      = 'applicationCustomFields'
    @submit       = 'updateCustomFields'
  )

  template(v-if = '!workflowPolicy.privateCustomer')
    .card.mb-0.border-bottom-0
      .card-header {{ $t('financialTitle') }}
      .card-body
        application-corporate-financial-data-form(:customer-data = 'applicationObj.corporateApplication')
    application-corporate-annual-report-list.mb-0.border-bottom-0(
      :reports = '$v.applicationObj.corporateApplication.annualReports.$model || []'
      @update  = 'updateAnnualReport'
    )

  application-financial-data-list(
    v-if      = 'financialOptions'
    :financial = 'applicationObj.customerFinancialData'
    :options  = 'financialOptions'
    @update   = 'updateCustomerFinancialData'
  )

  .card
    .card-header {{ $t('commentsTitle') }}
    .card-body
      fi-form-field.mb-1(
        :label     = '$t("customerComment")'
        label-cols = '3'
      )
        textarea.form-control.form-control-sm(
          v-model = 'applicationObj.customerComment'
          rows    = 3
        )
      fi-form-field.mb-1(
        :label     = '$t("internalComment")'
        label-cols = '3'
      )
        textarea.form-control.form-control-sm(
          v-model = 'applicationObj.internalComment'
          rows    = 3
        )
      button.btn.btn-primary(
        type      = 'submit'
        :disabled = 'saving'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      | &nbsp;
      button.btn.btn-secondary(
        type      = 'button'
        @click    = 'onCancel'
        :disabled = 'saving'
      ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiTable from '@/components/FiTable'

import ApplicationCustomerFormFields from './ApplicationCustomerFormFields'
import ApplicationCorporateCustomerForm from './ApplicationCorporateCustomerForm'
import ApplicationDebtorTerms from './ApplicationDebtorTerms'
import ApplicationCorporateFinancialDataForm from './ApplicationCorporateFinancialDataForm'
import ApplicationDataFormFields from './ApplicationDataFormFields'
import ApplicationPurchaseInfoFormFields from './ApplicationPurchaseInfoFormFields'
import ApplicationCorporateAnnualReportList from './ApplicationCorporateAnnualReportList'
import ApplicationSellerList from './ApplicationSellerList'

import pick from 'lodash/pick'
import merge from 'lodash/merge'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { rules, validation, validators } from '@/validation'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import { convertFeeRange } from '@/helpers'
import { classToPlain, plainToClass } from 'class-transformer'
import { CApplication } from '@/models/application/CApplication.ts'
import VCorporateApplication from '@/validation/VCorporateApplication'
import VPrivateApplication from '@/validation/VPrivateApplication'
import ApplicationFinancialDataList from '@/views/applications/ApplicationFinancialDataList'
import ApplicationCustomFieldsData from '@/views/applications/ApplicationCustomFieldsData'

export default {
  name: 'application-new-loan',

  components: {
    ApplicationFinancialDataList,
    ApplicationCustomFieldsData,
    ApplicationSellerList,
    FiTable,
    ApplicationCorporateAnnualReportList,
    ApplicationCorporateFinancialDataForm,
    ApplicationCorporateCustomerForm,
    ApplicationDebtorTerms,
    ApplicationCustomerFormFields,
    FiMultiselect,
    FiFormField,
    FiSelect,
    ApplicationDataFormFields,
    ApplicationPurchaseInfoFormFields,
    FiPhoneInput
  },

  mixins: [validation],

  props: {
    workflowPolicy: {
      type: WorkflowPolicy,
      required: true
    },
    copy: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      applicationObj: plainToClass(
        CApplication,
        {},
        {
          groups: this.workflowPolicy.groups
        }
      ),
      idDocExpireDate: '',
      purchaseFields: {
        purchasedGood: null,
        purchasedGoodCost: null
      },
      isLoading: true
    }
  },

  computed: {
    ...mapState('applications', ['application', 'applicationProduct', 'applicationCustomer']),
    ...mapGetters('classifiers', [
      'classifierByName',
      'classifierById',
      'optionsFromClassifier',
      'classifierList',
      'productTypeById',
      'optionsFromCreditIssuers'
    ]),
    ...mapGetters('products', ['optionsFromProductsBySectorAndSegment']),
    ...mapState('settings', ['settings']),
    ...mapGetters('settings', ['isLimitEnabled']),
    ...mapState('products', ['feeConfigs']),
    ...mapState('customFields', ['customFields']),
    customerFields () {
      return this.workflowPolicy.privateCustomer ? this.applicationObj.privateApplication : this.applicationObj.corporateApplication
    },
    creditIssuer () {
      return this.optionsFromCreditIssuers.find(({ value }) => value === this.applicationProduct.creditIssuerId)
    },
    allowedProductOptions () {
      const allowedIds = this.workflowPolicy?.productIds
      const {
        sectorId,
        segmentId
      } = this.customerFields
      if (!allowedIds) {
        return
      }
      return this.optionsFromProductsBySectorAndSegment({
        sectorId,
        segmentId
      }).filter(({ value }) => allowedIds.includes(value))
    },
    allowedSectors () {
      if (this.workflowPolicy?.privateCustomer) {
        return this.optionsFromClassifier('sectors').filter(item => (item.value === this.classifierByName('sectors', 'PRIVATE_CUSTOMER')?.id || item.value === this.classifierByName('sectors', 'EMPLOYEES')?.id))
      } else {
        return this.optionsFromClassifier('sectors').filter(item => (item.value !== this.classifierByName('sectors', 'PRIVATE_CUSTOMER')?.id || item.value !== this.classifierByName('sectors', 'EMPLOYEES')?.id))
      }
    },
    allowedSegments () {
      if (!this.applicationProduct?.segments?.length) return this.optionsFromClassifier('segments')
      return this.applicationProduct.segments.map(({ id }) => ({
        value: id,
        text: this.classifierById('segments', id).human
      }))
    },
    containsHirePurchaseInfo () {
      return this.applicationProduct.hirePurchaseInfo
    },
    saving () {
      return this.$vueLoading.isLoading('application:save')
    },
    submitDisabled () {
      return this.saving
    },
    productLoading () {
      return this.$vueLoading.isLoading('application:product:fetch')
    },
    convertedResidualRange () {
      const residualAmount = {
        feeCalculationType: this.classifierById('feeCalculationType', this.applicationObj.dataFields.residualAmountCalculationTypeId),
        feeMin: this.applicationProduct.residualAmountMin,
        feeMax: this.applicationProduct.residualAmountMax
      }
      return convertFeeRange(residualAmount, this.applicationObj.dataFields.creditAmount)
    },
    convertedUpfrontRange () {
      const upfrontAmount = {
        feeCalculationType: this.classifierById('feeCalculationType', this.applicationObj.dataFields.upfrontAmountCalculationTypeId),
        feeMin: this.applicationProduct.upfrontAmountMin,
        feeMax: this.applicationProduct.upfrontAmountMax
      }
      return convertFeeRange(upfrontAmount, this.applicationObj.dataFields.creditAmount)
    },
    applicationCustomFields () {
      if (!this.applicationProduct.customFields) return []
      return this.applicationProduct?.customFields?.filter(({ enabledApplication }) => enabledApplication)
        .map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
        .filter(({ customField }) => customField?.enabled)
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
    },
    financialOptions () {
      return this.applicationProduct?.financialData?.filter(({ isEnabled }) => isEnabled)
        .reduce(
          (res, { classifierKey, classifierCode, classifierValue, id }) =>
            ({ ...res, [classifierKey]: { ...res[classifierKey] ?? {}, [classifierCode]: { code: classifierCode, human: classifierValue, id } } }),
          {}
        )
    }
  },

  watch: {
    async 'applicationObj.applicationFields.productId' (value) {
      if (value) {
        await this.loadApplicationProduct({ id: value })
        this.processProduct()
      } else {
        this.SET_APPLICATION_PRODUCT({ product: {} })
      }
      this.$v.$reset()
    }
  },

  validations () {
    const mergedRules = { ...rules.applicationRules, ...rules.customerRules }
    const applicationFields = pick(mergedRules, Object.keys(this.applicationObj.applicationFields))
    const amountMax = Math.min(...[this.applicationProduct.amountMax, ...(this.isLimitEnabled ? [this.applicationCustomer?.creditLimit] : [])])
    const dataFields = merge(
      {},
      pick(mergedRules, Object.keys(this.applicationObj.dataFields)),
      {
        creditAmount: {
          gte: validators.gte(this.applicationProduct.amountMin),
          lte: validators.lte(amountMax)
        },
        loanLength: {
          gte: validators.gte(this.applicationProduct.periodMin),
          lte: validators.lte(this.applicationProduct.periodMax)
        },
        residualAmount: {
          required: validators.requiredIf(this.workflowPolicy.isLeasing),
          gte: validators.gte(this.convertedResidualRange.feeMin),
          lte: validators.lte(this.convertedResidualRange.feeMax)
        },
        residualAmountCalculationTypeId: {
          required: validators.requiredIf(this.workflowPolicy.isLeasing)
        },
        upfrontAmount: {
          required: validators.requiredIf(this.workflowPolicy.isLeasing),
          gte: validators.gte(this.convertedUpfrontRange.feeMin),
          lte: validators.lte(this.convertedUpfrontRange.feeMax)
        },
        upfrontAmountCalculationTypeId: {
          required: validators.requiredIf(this.workflowPolicy.isLeasing)
        }
      })

    const purchaseFields = pick(mergedRules, Object.keys(this.purchaseFields))

    if (this.workflowPolicy.isLeasing || this.containsHirePurchaseInfo) {
      applicationFields.sellers = {
        required: validators.required,
        minLength: validators.minLength(1)
      }
    }

    let fields = {
      applicationFields,
      dataFields
    }

    if (this.workflowPolicy.privateCustomer) {
      fields = {
        ...fields,
        privateApplication: VPrivateApplication
      }
    } else {
      fields = {
        ...fields,
        corporateApplication: VCorporateApplication
      }
    }

    if (this.containsHirePurchaseInfo) {
      fields = merge({}, {
        ...fields,
        purchaseFields
      }, {
        dataFields: {
          creditAmount: {
            lte: validators.lte(this.purchaseFields.purchasedGoodCost)
          }
        }
      })
    }

    return { applicationObj: fields }
  },

  async created () {
    await this.loadProducts({ group: this.workflowPolicy.productType.group })
    await this.loadFeeConfig({ group: this.workflowPolicy.productType.group })
    await this.loadCustomFields()
    if (this.allowedProductOptions.length === 1) {
      const [product] = this.allowedProductOptions
      this.applicationObj.applicationFields.productId = product.value
    } else {
      this.SET_APPLICATION_PRODUCT({ product: {} })
    }

    if (this.customerId) {
      this.customerFields.customerId = this.customerId
    }
    if (this.copy) {
      this.applicationObj = plainToClass(
        CApplication,
        this.application,
        {
          groups: this.workflowPolicy.groups,
          strategy: 'exposeAll',
          excludeExtraneousValues: true
        }
      )
    }
    this.isLoading = false
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields']),
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    ...mapMutations('applications', ['SET_APPLICATION_PRODUCT']),
    ...mapActions('applications', ['createApplication', 'loadApplicationProduct']),
    processProduct () {
      if (this.copy) return
      this.applicationObj.dataFields.creditAmount = this.applicationProduct.amount
      this.applicationObj.dataFields.upfrontAmount = this.applicationProduct.upfrontAmount
      this.applicationObj.dataFields.upfrontAmountCalculationTypeId = this.classifierByName('feeCalculationType', this.applicationProduct.upfrontAmountCalculationType?.code)?.id
      this.applicationObj.dataFields.residualAmount = this.applicationProduct.residualAmount
      this.applicationObj.dataFields.residualAmountCalculationTypeId = this.classifierByName('feeCalculationType', this.applicationProduct.residualAmountCalculationType?.code)?.id
      this.applicationObj.dataFields.loanLength = this.applicationProduct.period
    },
    newCorporateApplication () {
      if (this.$route.params.customer) {
        this.$v.applicationObj.corporateApplication.naceId.$model = this.$route.params.customer.corporateCustomer.naceId
        this.$v.applicationObj.corporateApplication.emtakId.$model = this.$route.params.customer.corporateCustomer.naceId
        this.$v.applicationObj.corporateApplication.accountExternalId.$model = this.$route.params.customer.defaultExternalAccount?.accountExternalNumber
      }
      return this.$v.applicationObj.corporateApplication
    },
    async onSubmit () {
      if (this.applicationObj.customFieldValues) {
        this.applicationObj.customFieldValues.forEach((customFieldValue, index) => {
          if (customFieldValue.customFieldValue === '') {
            delete this.applicationObj.customFieldValues[index]
          }
        })
      }
      if (this.validate()) {
        await this.createApplication({
          applicationData: classToPlain(this.applicationObj, {
            groups: this.workflowPolicy.groups,
            excludeExtraneousValues: true
          })
        })
        this.$router.push({
          name: 'Application',
          params: { id: this.application.id }
        })
      }
    },
    onCancel () {
      this.$router.push({ name: 'Applications' })
    },
    selectCustomer (customer) {
      const {
        privateCustomer: {
          idDocExpireDate,
          idDocId,
          idDocNumber,
          maritalStatusId,
          dependantNumber
        } = {}
      } = customer
      if (this.workflowPolicy.privateCustomer) {
        this.applicationObj.privateApplication.idDocId = idDocId
        this.applicationObj.privateApplication.idDocNumber = idDocNumber
        this.idDocExpireDate = idDocExpireDate
        this.applicationObj.privateApplication.maritalStatusId = maritalStatusId
        this.applicationObj.privateApplication.dependantNumber = dependantNumber
        this.applicationObj.privateApplication.residenceCountryId = customer.residenceCountryId
        this.applicationObj.privateApplication.residenceType = customer.residenceType
        this.applicationObj.privateApplication.residenceTypeId = customer.residenceTypeId
      }
    },
    updateAnnualReport ({
      index = -1,
      report,
      done
    } = {}) {
      const newReportList = [...this.applicationObj.corporateApplication?.annualReports ?? []]

      newReportList.splice.apply(newReportList, [index, ~index && 1, report].filter(i => typeof i !== 'undefined'))

      this.applicationObj.corporateApplication.annualReports = newReportList

      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    updateSeller ({
      index = -1,
      seller,
      done
    } = {}) {
      const newSellerList = [...this.applicationObj.applicationFields.sellers]

      newSellerList.splice.apply(newSellerList, [index, ~index && 1, seller].filter(i => typeof i !== 'undefined'))

      this.applicationObj.applicationFields.sellers = newSellerList

      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    updateCustomFields ({ data, done }) {
      this.applicationObj.customFieldValues = data
      this.$nextTick(() => {
        done()
      })
    },
    updateCustomerFinancialData ({ done, id, ...financialData }) {
      if (financialData.financialDataValue) {
        if (!financialData.id) financialData.id = id
      }
      const newCustomerFinancialDataList = [...this.applicationObj.customerFinancialData ?? []]
      const index = newCustomerFinancialDataList?.findIndex(value => value.id === id) ?? -1

      newCustomerFinancialDataList.splice.apply(newCustomerFinancialDataList, [index, ~index && 1, financialData].filter(i => typeof i !== 'undefined'))

      this.applicationObj.customerFinancialData = newCustomerFinancialDataList.filter(el => el.id)
      done()
    }
  }
}
</script>


<i18n>
en:
  title:              "New application"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  customerTitle:      "Customer data"
  dataTitle:          "Application data"
  purchaseTitle:      "Hire purchase info"
  commentsTitle:      "Comments"
  customerComment:    "Client's comment"
  internalComment:    "Internal note"
  addSeller:          "Add seller"
  debtorsTitle:       "Third-party data"
  addDebtor:          "+ Add new"
  sellerRequired:     "At least one seller is required"
  debtorRequired:     "At least one third-party is required"
  debtorTerms:        "Third-party terms"
  financialTitle:     "Financial data"
  annualReports:      "Annual reports"
et:
  title:              "New application"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  customerTitle:      "Customer data"
  dataTitle:          "Application data"
  purchaseTitle:      "Hire purchase info"
  commentsTitle:      "Comments"
  customerComment:    "Client's comment"
  internalComment:    "Internal note"
  addSeller:          "Add seller"
  debtorsTitle:       "Third-party data"
  addDebtor:          "+ Add new"
  sellerRequired:     "At least one seller is required"
  debtorRequired:     "At least one third-party is required"
  debtorTerms:        "Third-party terms"
  financialTitle:     "Financial data"
  annualReports:      "Annual reports"
ru:
  title:              "New application"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  customerTitle:      "Customer data"
  dataTitle:          "Application data"
  purchaseTitle:      "Hire purchase info"
  commentsTitle:      "Comments"
  customerComment:    "Client's comment"
  internalComment:    "Internal note"
  sellersTitle:       "Sellers"
  addSeller:          "Add seller"
  debtorsTitle:       "Third-party data"
  addDebtor:          "+ Add new"
  sellerRequired:     "At least one seller is required"
  debtorRequired:     "At least one third-party is required"
  debtorTerms:        "Third-party terms"
  financialTitle:     "Financial data"
  annualReports:      "Annual reports"
</i18n>
