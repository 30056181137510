<template lang="pug">
.application-debtor-terms.card-body
  .col-lg-6
    fi-form-field(:label = '$t("limit")')
      fi-money-input.form-control.form-control-sm.animated.fadeIn(
        type         = 'text'
        v-model.trim = 'debtorTerms.limit.$model'
      )
    fi-form-field(:label = '$t("advanceRate")')
      percent-input.form-control.form-control-sm.animated.fadeIn(
        type         = 'text'
        v-model.trim = 'debtorTerms.advanceRate.$model'
      )
    fi-form-field(:label = '$t("invoiceLength")')
      .row
        .col
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.number = 'debtorTerms.invoiceLengthMin.$model'
          )
        .col
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.number = 'debtorTerms.invoiceLengthMax.$model'
          )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'

export default {
  name: 'application-debtor-terms',
  components: { PercentInput, FiMoneyInput, FiFormField },
  props: {
    debtorTerms: {
      type: Object,
      required: true
    }
  }
}
</script>


<i18n>
en:
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
et:
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
ru:
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
</i18n>
