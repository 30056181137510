<template lang="pug">
fi-modal.application-seller-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = 'title'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(:label = '$t("existingCustomer")')
      fi-customer-select(
        v-model      = 'selectedCustomerModel'
        :placeholder = '$t("selectCustomer")'
      )
    fi-form-field(:label = '$t("sector")')
      fi-select(
        v-model.number = 'sellerData.sectorId'
        :options       = 'optionsFromClassifier("sectors")'
        :disabled      = 'Boolean(sellerData.sellerId)'
        required
        sm
      )
    fi-form-field(:label = '$t("segment")')
      fi-select(
        v-model.number = 'sellerData.segmentId'
        :options       = 'optionsFromClassifier("segments")'
        :disabled      = 'Boolean(sellerData.sellerId)'
        required
        sm
      )
    fi-form-field(:label = '$t("name")')
      input.form-control.form-control-sm.animated.fadeIn(
        type         = 'text'
        v-model.trim = 'sellerData.customerName'
        :disabled      = 'Boolean(sellerData.sellerId)'
      )

    fi-form-field(:label = '$t("idCode")')
      input.form-control.form-control-sm.animated.fadeIn(
        type           = 'text'
        v-model.number = 'sellerData.idCode'
        :disabled      = 'Boolean(sellerData.sellerId)'
      )

    fi-form-field(:label = '$t("phone")')
      fi-phone-input.form-control-sm.form-control.animated.fadeIn(
        v-model.trim = 'sellerData.phoneNumber'
        :countries   = 'countries'
      )

    fi-form-field(:label = '$t("email")')
      input.form-control.form-control-sm.animated.fadeIn(
        type         = 'text'
        v-model.trim = 'sellerData.email'
      )

    fi-form-field(:label = '$t("country")')
      fi-select(
        v-model.number = 'sellerData.countryId'
        :options       = 'optionsFromClassifier("countries")'
        :placeholder   = '$t("countryPlaceholder")'
        required
        sm
      )

    fi-form-field(:label = '$t("county")')
      fi-select(
        v-model.number = 'sellerData.countyId'
        :options       = 'counties'
        :placeholder   = '$t("countyPlaceholder")'
        required
        sm
      )

    fi-form-field(:label = '$t("city")')
      input.form-control.form-control-sm.animated.fadeIn(
        type         = 'text'
        v-model.trim = 'sellerData.city'
      )

    fi-form-field(:label = '$t("address")')
      input.form-control.form-control-sm.animated.fadeIn(
        type         = 'text'
        v-model.trim = 'sellerData.address'
      )

    fi-form-field(:label = '$t("postalCode")')
      input.form-control.form-control-sm(v-model.trim = 'sellerData.postalCode')

  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')

    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>

<script>
import { classToClass, plainToClass } from 'class-transformer'
import { CSeller } from '@/models/application/CSeller.ts'
import { customerName, findCustomerMainContact } from '@/helpers'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiSelect from '@/components/FiSelect'
import FiPhoneInput from '@/components/FiPhoneInput'
import { mapGetters } from 'vuex'
import api from '@/api'

export default {
  name: 'application-seller-modal',

  components: { FiPhoneInput, FiSelect, FiModal, FiFormField, FiCustomerSelect },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    seller: {
      type: CSeller,
      default: () => plainToClass(CSeller, {})
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    selectedCustomer: null,
    sellerData: classToClass(vm.seller)
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromCountiesByCountry']),
    saving () {
      return this.loader
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    title () {
      return this.seller.sellerId ? this.$t('titleEdit') : this.$t('titleAdd')
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
        const { fullAddress: address } = selected.defaultAddress
        this.sellerData = plainToClass(CSeller, {
          ...selected,
          ...selected.defaultAddress,
          address,
          sellerId: selected?.id,
          phoneNumber: findCustomerMainContact(selected.phoneNumbers),
          email: findCustomerMainContact(selected.emails),
          customerName: customerName(selected)
        }, { excludeExtraneousValues: true })
      }
    },
    countries () {
      return Object.values(this.optionsFromClassifier('countries', true)).map(({ value }) => value)
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.sellerData.countryId)
    }
  },

  methods: {
    async resetData () {
      Object.assign(this.$data, this.$options.data.call(this, this))
      if (this.seller.sellerId) {
        this.selectedCustomer = (await api.getCustomer({ id: this.seller.sellerId })).data
      }
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      this.$emit('submit', {
        seller: this.sellerData,
        done: () => this.$refs.modal.hide()
      })
    }
  }
}
</script>


<i18n>
en:
  titleAdd:           "Add seller"
  titleEdit:          "Edit seller"
  existingCustomer:   "Existing customer"
  selectCustomer:     "Select customer"
  name:               "Name"
  idCode:             "ID Code"
  sector:             "Sector"
  segment:            "Segment"
  phone:              "Phone"
  email:              "Email"
  country:            "Country"
  countryPlaceholder: "Select country"
  county:             "County"
  countyPlaceholder:  "Select county"
  postalCode:         "Postal code"
  city:               "City"
  address:            "Address"
et:
  titleAdd:           "Add seller"
  titleEdit:          "Edit seller"
  existingCustomer:   "Existing customer"
  selectCustomer:     "Select customer"
  name:               "Name"
  idCode:             "ID Code"
  sector:             "Sector"
  segment:            "Segment"
  phone:              "Phone"
  email:              "Email"
  country:            "Country"
  countryPlaceholder: "Select country"
  county:             "County"
  countyPlaceholder:  "Select county"
  postalCode:         "Postal code"
  city:               "City"
  address:            "Address"
ru:
  titleAdd:           "Add seller"
  titleEdit:          "Edit seller"
  existingCustomer:   "Existing customer"
  selectCustomer:     "Select customer"
  name:               "Name"
  idCode:             "ID Code"
  sector:             "Sector"
  segment:            "Segment"
  phone:              "Phone"
  email:              "Email"
  country:            "Country"
  countryPlaceholder: "Select country"
  county:             "County"
  countyPlaceholder:  "Select county"
  postalCode:         "Postal code"
  city:               "City"
  address:            "Address"
</i18n>
