<template lang="pug">
.application-seller-list.card
  .card-header {{ $t('title') }}
    .fa-pull-right
      button.btn.btn-link.py-0(@click.prevent = 'showNewSellerModal = true') {{ $t('addSeller') }}
  .card-body
    fi-table(
      :fields = 'fields'
      :items  = 'sellers'
    )
      template(#default = '{ items }')
        application-seller-list-item(
          v-for   = '(seller, index) in items'
          :key    = 'seller.idCode'
          :seller = 'seller'
          @edit   = '$emit("update", { ...$event, index })'
          @remove = '$emit("update", { index })'
        )
    slot(name = 'alert')
  application-seller-modal(
    v-model = 'showNewSellerModal'
    @submit = '$emit("update", $event)'
  )
</template>


<script>
import FiTable from '@/components/FiTable'
import ApplicationSellerListItem from '@/views/applications/ApplicationSellerListItem'
import ApplicationSellerModal from '@/views/applications/ApplicationSellerModal'

export default {
  name: 'application-seller-list',

  components: { ApplicationSellerModal, ApplicationSellerListItem, FiTable },

  props: {
    sellers: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    showNewSellerModal: false
  }),

  computed: {
    fields () {
      return [
        {
          key: 'customerName',
          label: this.$t('name')
        },
        {
          key: 'idCode',
          label: this.$t('idCode')
        },
        {
          key: 'sector',
          label: this.$t('sector')
        },
        {
          key: 'phoneNumber',
          label: this.$t('phone')
        },
        {
          key: 'email',
          label: this.$t('email')
        },
        {
          key: 'fullAddress',
          label: this.$t('address')
        },
        {}
      ]
    }
  },

  methods: {
    addSeller () {

    }
  }
}
</script>


<i18n>
en:
  title:     "Sellers"
  addSeller: "+ Add seller"
  name:      "Name"
  idCode:    "ID Code"
  sector:    "Sector"
  phone:     "Phone"
  email:     "Email"
  address:   "Address"
et:
  title:     "Sellers"
  addSeller: "+ Add seller"
  name:      "Name"
  idCode:    "ID Code"
  sector:    "Sector"
  phone:     "Phone"
  email:     "Email"
  address:   "Address"
ru:
  title:     "Sellers"
  addSeller: "+ Add seller"
  name:      "Name"
  idCode:    "ID Code"
  sector:    "Sector"
  phone:     "Phone"
  email:     "Email"
  address:   "Address"
</i18n>
