<template lang="pug">
tr.application-seller-list-item
  td {{ seller.customerName }}
  td {{ seller.idCode }}
  td {{ seller.sectorId }}
  td {{ seller.phoneNumber }}
  td {{ seller.email }}
  td {{ seller.address }}
  td
    .btn-toolbar
      .btn-group.btn-group-sm
        button.btn.btn-link(@click.prevent = 'showEditSellerModal = true')
          i.far.fa-edit.fa-fw.fa-lg
        button.btn.btn-link(@click.prevent = '$emit("remove")')
          i.far.fa-trash-alt.fa-lg.text-danger
  application-seller-modal(
    v-model = 'showEditSellerModal'
    :seller = 'seller'
    @submit = '$emit("edit", $event)'
  )
</template>


<script>
import { CSeller } from '@/models/application/CSeller.ts'
import ApplicationSellerModal from '@/views/applications/ApplicationSellerModal'

export default {
  name: 'application-seller-list-item',
  components: { ApplicationSellerModal },
  props: {
    seller: {
      type: CSeller,
      required: true
    }
  },

  data: () => ({
    showEditSellerModal: false
  })
}
</script>
